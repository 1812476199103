<template>
  <div class="project" v-if="project">
    <div class="container">
      <div class="main">
        <div class="sidebar">
          <div class="card-list" v-if="projectList.length > 0">
            <div class="card" :class="{ active: item.id == $route.params.id && item.special }" v-for="item in projectList" :key="item.name" @click="toDetail(item)">
              <div class="card-header">{{ getText(item, 'name') }}</div>
              <div class="card-content">
                <el-row type="flex" justify="space-between">
                  <el-col :span="12">
                    <div class="card-meta">
                      <span class="card-label">{{ $t('fund.DeployedCapital') }}：</span>
                      <span class="card-value">{{ item.totalInvest }}</span>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="card-meta">
                      <span class="card-label">{{ $t('fund.ClosingDate') }}：</span>
                      <span class="card-value">{{ item.establish }}</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="project-s1">
            <h2 class="title" style="margin-bottom: 15px">{{ getText(project, 'name') }}</h2>
            <div class="item">
              <div class="item-pic">
                <el-image style="width: 80px; height: 80px" :src="project.logoUrl" fit="cover"></el-image>
              </div>
              <div class="item-content">
                <div class="item-head">
                  <h2 class="title">{{ getText(project, 'companyName') }}</h2>
                </div>
                <a :href="`${project.website}`" target="_blank" class="link">{{ project.website }}</a>
              </div>
              <div class="btns">
                <div class="fund-size">
                  {{ $t('fund.DeployedCapital') }}：<span class="price">{{ project.totalInvest }}</span>
                </div>
              </div>
            </div>
            <div class="desc">{{ getText(project, 'introduce') }}</div>
            <el-row style="margin-top: 10px">
              <el-button type="primary" size="small" @click="drawer = true" v-if="project.founderList.length > 0" style="margin-right: 10px">{{ $t('project.FoundingTeam') }}</el-button>
              <el-button type="primary" size="small" @click="shareDrawer = true" style="margin-left: 0; margin-right: 10px" v-if="eventList.length > 0">{{ $t('project.RelatedSharing') }}</el-button>
              <el-popover placement="bottom" width="314" trigger="click" v-if="lpReportFileList.length > 0">
                <div class="download-list">
                  <ul>
                    <li v-for="report in lpReportFileList" :key="report.name">
                      <a :href="report.fileUrl" target="_blank" download>
                        <div class="name">{{ getText(report, 'name') }}</div>
                      </a>
                    </li>
                  </ul>
                </div>
                <el-button type="primary" size="small" slot="reference">{{ $t('fund.lpReport') }}</el-button>
              </el-popover>
            </el-row>
            <div class="project-table" style="margin-top: 20px" v-if="project.projectInvestList.length > 0">
              <el-table :data="project.projectInvestList" style="width: 100%">
                <el-table-column prop="investDate" :label="$t('project.investmentDate')"> </el-table-column>
                <el-table-column prop="enterRound" :label="$t('entryRound')"> </el-table-column>
                <el-table-column prop="investRound" :label="$t('project.investmentRound')"> </el-table-column>
                <el-table-column prop="investAmount" :label="$t('project.investmentAmount')"> </el-table-column>
                <el-table-column prop="valuation" :label="$t('PostMoneyValuation')"> </el-table-column>
                <el-table-column prop="latestValuation" :label="$t('LatestValuation')"> </el-table-column>
                <el-table-column prop="moic" label="MOIC"> </el-table-column>
              </el-table>
            </div>
          </div>

          <div class="section" v-if="project.news">
            <div class="section-header">
              <h2>{{ $t('project.LatestUpdates') }}</h2>
              <el-popover placement="bottom" width="314" trigger="click" v-if="project.reportFileList.length > 0">
                <div class="download-list">
                  <ul>
                    <li v-for="report in project.reportFileList" :key="report.name">
                      <a :href="report.fileUrl" target="_blank" download>
                        <div class="name">{{ report.fileName }}</div>
                      </a>
                    </li>
                  </ul>
                </div>
                <el-button type="primary" slot="reference" size="small">{{ $t('project.InvestmentReports') }}</el-button>
              </el-popover>
            </div>
            <div class="section-content">
              <div class="card">
                <div class="card-content">
                  {{ getText(project, 'news') }}
                </div>
              </div>
            </div>
          </div>

          <div class="section mb16" v-if="project.publicWorthList.length > 0">
            <div class="section-header">
              <h2>{{ $t('project.FairValue') }}</h2>
            </div>
            <div class="section-content">
              <el-table :data="project.publicWorthList" :show-header="false" style="width: 100%">
                <el-table-column prop="time"></el-table-column>
                <el-table-column prop="amount"></el-table-column>
              </el-table>
            </div>
          </div>

          <div class="section" v-if="project.chartList.length > 0">
            <div class="section-header">
              <h2>{{ $t('project.Metrics') }}</h2>
            </div>
            <div class="section-content">
              <div class="chart-list">
                <el-row :gutter="16">
                  <el-col :span="24" v-for="chart in project.chartList" :key="chart.enName">
                    <div class="card">
                      <div class="card-header">{{ getText(chart, 'name') }}</div>
                      <div class="card-content">
                        <EchartLine :chart-info="chart" :key="project.id" v-if="chart.chartType == 'LINE'" />
                        <EchartBar :chart-info="chart" :key="project.id" v-if="chart.chartType == 'BAR'" />
                        <EchartPie :chart-info="chart" :key="project.id" v-if="chart.chartType == 'PIE'" />
                        <EchartMixLineBar :chart-info="chart" :key="project.id" v-if="chart.chartType == 'LINE_AND_BAR'" />
                        <EchartBarStack :chart-info="chart" :key="project.id" v-if="chart.chartType == 'MULTI_BAR'" />
                      </div>
                      <div class="card-footer" :style="chart.remark ? '' : 'background: #F7F8FA;'">
                        <div class="tips">
                          <template v-if="chart.remark">{{ $t('remark') }}：{{ getText(chart, 'remark') }}</template>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>

          <div class="section" v-if="newsList.length > 0">
            <div class="section-header">
              <h2>{{ $t('PortfolioNews') }}</h2>
              <router-link :to="`/news/${$route.params.id}`" class="more">{{ $t('moreNews') }}<i class="el-icon-arrow-right"></i></router-link>
            </div>
            <div class="section-content">
              <div class="news-list">
                <div class="item" @click="toNewsDetail(item)" v-for="item in newsList" :key="item.id">
                  <div class="item-pic"><el-image style="width: 168px; height: 110px" :src="item.coverImage" fit="cover"></el-image></div>
                  <div class="item-content">
                    <div class="title">{{ getText(item, 'title') }}</div>
                    <div class="time">{{ item.publishTime }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 创始人团队 -->
    <el-drawer :title="$t('project.FoundingTeam')" :size="570" :visible.sync="drawer" :direction="direction">
      <div class="team-list">
        <div class="item" v-for="item in project.founderList" :key="item.name">
          <div class="item-pic">
            <el-image style="width: 126px; height: 155px" :src="item.avatar" fit="cover"></el-image>
          </div>
          <div class="item-content">
            <div class="name">{{ getText(item, 'name') }}</div>
            <div class="title" v-if="item.positional">{{ getText(item, 'positional') }}</div>
            <div class="desc">{{ getText(item, 'introduce') }}</div>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 相关分享 -->
    <el-drawer :title="$t('project.RelatedSharing')" :size="570" :visible.sync="shareDrawer" :direction="direction">
      <div class="share-list">
        <div class="item" v-for="item in eventList" :key="item.id">
          <div class="item-pic">
            <el-image style="width: 215px; height: 140px" :src="item.coverImage" fit="cover"></el-image>
            <div class="tags">
              <div class="tag">{{ getText(item.category, 'name') }}</div>
              <Tag :status="item.status" />
            </div>
          </div>
          <div class="item-content">
            <div class="title">{{ getText(item, 'title') }}</div>
            <div class="time">
              <b>{{ $t('events.StartTime') }}：</b>{{ $t('BeijingTime') }} {{ item.startTime }}
            </div>
            <EventButton :event="item" type="primary" />
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import api from '@/api'
import EchartLine from '@/components/echarts/Line.vue'
import EchartPie from '@/components/echarts/Pie.vue'
import EchartBar from '@/components/echarts/Bar.vue'
import EchartBarStack from '@/components/echarts/BarStack.vue'
import EchartMixLineBar from '@/components/echarts/MixLineBar.vue'
import Tag from '@/components/Tag'
import EventButton from '@/components/EventButton'

export default {
  name: 'Project',
  components: {
    EchartLine,
    EchartPie,
    EchartBar,
    EchartBarStack,
    EchartMixLineBar,
    Tag,
    EventButton,
  },
  data() {
    return {
      drawer: false,
      shareDrawer: false,
      direction: 'rtl',
      project: null,
      projectList: [],
      newsList: [],
      eventList: [],
      lpReportFileList: [],
    }
  },
  watch: {
    $route() {
      this.purchasedFundAndProjectList()
      this.getProject()
      this.getNewsList()
      this.getEventList()
    },
  },
  created() {
    this.purchasedFundAndProjectList()
    this.getProject()
    this.getNewsList()
    this.getEventList()
  },
  methods: {
    purchasedFundAndProjectList() {
      api.purchasedFundAndProjectList().then((res) => {
        this.projectList = res.data
      })
    },
    getProject() {
      api.getProject(this.$route.params.id).then((res) => {
        this.project = res.data
        const { lpReportFileList } = res.data
        this.lpReportFileList = lpReportFileList
      })
    },
    getNewsList() {
      let data = {
        size: 5,
        page: 0,
      }
      api.getNewsList(this.$route.params.id, data).then((res) => {
        this.newsList = res.data.content
      })
    },
    toDetail(obj) {
      if (obj.special) {
        this.$router.push(`/project/${obj.id}`)
      } else {
        this.$router.push(`/fund/${obj.id}`)
      }
    },
    getEventList() {
      let data = {
        projectId: this.$route.params.id,
      }
      api.activityList(data).then((res) => {
        this.eventList = res.data
      })
    },
  },
}
</script>
